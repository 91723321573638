import React from 'react'
import Layout from '../components/layouts/Layout'
import { graphql, Link, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import {
  FullWidthBg,
  GridContainer,
  InfoPagesWrapper,
} from '../components/layouts'
// import aboutPhoto2 from '../images/aboutPhoto2.jpg'
import styled from 'styled-components'
import { borderRadius } from '../components/utilities'
import SEO from '../components/seo'
import { Button } from '../components/elements'

const AboutWrapper = styled(InfoPagesWrapper)`
  height: auto;
  width: 100%;
  img {
    //display: block;
    z-index: 0;
    border-radius: ${borderRadius};
  }
`

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      tom: file(relativePath: { regex: "/tom.jpg/" }) {
        childImageSharp {
          fluid(maxHeight: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="About"
        keywords={['About TC Web Design, About Tom Caraher']}
      />
      {/*<FullWidthBg>*/}
      <AboutWrapper>
        <h1>About Me</h1>
        <GridContainer
          modifiers="twoCol"
          css={`
            grid-gap: 2rem;
            padding-top: 2rem;
            height: auto;
            width: 83%;
          `}
        >
          <div
            css={`
              width: auto;
              display: grid;
              button {
                margin: 2rem auto;
              }
            `}
          >
            <p>
              {`My name is Tom Caraher. I am a web designer and developer based in Kilkenny, Ireland, specializing in both WordPress and modern(aka fast!) coding frameworks. The driving force behind my passion for this work is the opportunity to be creative. I love getting to know you and your business on a personal level in order to design and build a site that fits, and one that you are proud to have as the first thing a potential customer sees that represents you.`}
            </p>

            <p>
              {`My design philosophy is to create clean and honest designs in
              which the user feels comfortable, able, and willing to take in
              your message effectively. I also strongly believe that in these
              complex times it is important to build ethical user experiences
              that aren't manipulative or predatory, and of course that are
              built from the ground up with GDPR compliance, therefore managing
              your users' data ethically.`}
            </p>
            <p>
              {`Once past the design stage, the development work is equally
              important. Getting your site to load quickly and reliably,
              beginning a search engine optimisation campaign, and getting your
              e-commerce solution up and running are just some of the areas that
              I'll be working on.`}
            </p>
            <p>
              {`If you would like more information, just have a couple questions,
              or would like to work with me please get in touch!`}
            </p>

            <Button to="/contact">Get in touch!</Button>
          </div>
          <div
            css={`
              width: 60%;
            `}
          >
            <Img fluid={data.tom.childImageSharp.fluid} />
          </div>
        </GridContainer>
      </AboutWrapper>
      {/*</FullWidthBg>*/}
    </Layout>
  )
}

export default About
